<!--派单规则设置-->
<template>
  <div>
    <div style="margin-top: 20px; text-align: center">
      <el-form
        label-width="90px"
        class="ele-form-search"
        :model="form"
        :rules="rules"
        ref="form"
      >
        <el-row :gutter="10">
          <el-col :lg="9" :md="14">
            <el-form-item
              label-width="135px"
              label="短信单价"
              prop="note_price"
            >
              <el-input
                v-model="form.note_price"
                placeholder="请输入"
                clearable
                oninput="value=value.replace(/^([0-9-]\d*\.?\d{0,2})?.*$/,'$1')"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="9" :md="14">
            <el-form-item
              label-width="135px"
              label="赠送短信条数"
              prop="give_note"
            >
              <el-input
                v-model.number="form.give_note"
                placeholder="请输入"
                clearable
                type="number"
                @mousewheel.native.prevent
                @DOMMouseScroll.native.prevent
                oninput="this.value = this.value && parseInt(this.value);this.value < 0 || this.value>10000 && (this.value = 10000)"
                maxlength="5"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :lg="9" :md="14">
            <el-form-item
              label-width="135px"
              label="电话单价"
              prop="phone_price"
            >
              <el-input
                v-model="form.phone_price"
                placeholder="请输入"
                clearable
                oninput="value=value.replace(/^([0-9-]\d*\.?\d{0,2})?.*$/,'$1')"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="9" :md="14">
            <el-form-item
              label-width="135px"
              label="赠送电话时长"
              prop="give_phone"
            >
              <el-input
                v-model.number="form.give_phone"
                placeholder="请输入"
                clearable
                @mousewheel.native.prevent
                @DOMMouseScroll.native.prevent
                type="number"
                oninput="this.value = this.value && parseInt(this.value);this.value < 0 || this.value>10000 && (this.value = 10000)"
                maxlength="5"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :lg="9" :md="14">
            <el-form-item
              label-width="135px"
              label="语音提醒单价"
              prop="voice_price"
            >
              <el-input
                v-model="form.voice_price"
                placeholder="请输入"
                clearable
                oninput="value=value.replace(/^([0-9-]\d*\.?\d{0,2})?.*$/,'$1')"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="9" :md="14">
            <el-form-item
              label-width="135px"
              label="赠送语音提醒次数"
              prop="give_voice"
            >
              <el-input
                v-model.number="form.give_voice"
                placeholder="请输入"
                clearable
                type="number"
                @mousewheel.native.prevent
                @DOMMouseScroll.native.prevent
                oninput="this.value = this.value && parseInt(this.value);this.value < 0 || this.value>10000 && (this.value = 10000)"
                maxlength="5"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :lg="9" :md="14">
            <el-form-item
              label-width="135px"
              label="提现手续费比例"
              prop="commission"
            >
              <el-input
                v-model="form.commission"
                placeholder="请输入"
                clearable
                @mousewheel.native.prevent
                @DOMMouseScroll.native.prevent
                oninput="value=value.replace(/^([0-9-]\d*\.?\d{0,2})?.*$/,'$1')"
                maxlength="5"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="9" :md="14">
            <el-form-item
              label-width="135px"
              label="最低提现金额"
              prop="min_withdrawal"
            >
              <el-input
                v-model="form.min_withdrawal"
                placeholder="请输入"
                clearable
                oninput="value=value.replace(/^([0-9-]\d*\.?\d{0,2})?.*$/,'$1')"
                maxlength="5"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="3">
          <el-col :lg="2" :md="14">
            <el-form-item label-width="135px" label="可用功能">
              <!-- <el-checkbox v-model="form.checked">实时结算</el-checkbox> -->
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :lg="24" :md="14">
            <el-form-item label-width="135px">
              <div class="flex_start">
                <el-checkbox
                  v-model="checkAll"
                  :indeterminate="isIndeterminate"
                  @change="handleCheckAllChange"
                >
                  全选
                </el-checkbox>
              </div>

              <el-scrollbar
                style="height: 50vh"
                wrapStyle="overflow-x: hidden;"
              >
                <el-tree
                  :check-strictly="isCheck"
                  :expand-on-click-node="false"
                  ref="tree"
                  :data="authData"
                  :props="{ label: 'title' }"
                  node-key="id"
                  :default-expand-all="false"
                  :default-checked-keys="[checked]"
                  show-checkbox
                >
                  <span slot-scope="{ data }">
                    <!--<i :class="data.icon"></i>-->
                    <span> {{ data.label }}</span>
                  </span>
                </el-tree>
              </el-scrollbar>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-button style="width: 240px" @click="close_emit">取消</el-button>
      <el-button
        style="margin-left: 20px; width: 240px"
        type="primary"
        @click="save('form')"
        >提交
      </el-button>
    </div>
  </div>
</template>

<script>
//引入的接口
import {
  cascader_list,
  get_relevancy,
  relevancy_menus,
} from "@/api/humanResources";
import { getMenusListApi, getMenusCascaderApi } from "@/api/system";
import {
  v2_platform,
  huiyuan_add,
  huiyuan_detail,
  huiyuan_xiugai,
  get_menu,
  free_huiyuan_xiugai,
  free_huiyuan_detail,
} from "@/api/operate";

import axios from "axios";
import setting from "@/config/setting";
export default {
  props: {
    id: Number,
  },
  data() {
    return {
      checkAll: false,
      // 全选状态
      isIndeterminate: false,
      // 权限数据
      authData: [],
      // 权限数据请求状态
      authLoading: false,
      // 提交状态
      loading: false,

      isCheck: false,
       form: {
        weight: 0,
        title:'',
        note_price: 0,
        give_note: 0,
        phone_price: 0,
        give_phone:0,
        voice_price:0,
        give_voice:0,
        commission:0,
        min_withdrawal:0,
        status:0,
        checked: false,
        rights: {
          facilitator: {
            label: "推广服务商返佣",
            switch: false,
            member_brokerage: {
              label: "服务商充值会员返佣比例",
              value: "",
            },
          },
          rtgs: {
            label: "实时结算",
            switch: false,
          },
          shared: {
            label: "推广个体司机返佣",
            switch: false,
            member_brokerage: {
              label: "个体司机充值会员返佣比例",
              value: "",
            },
            order_brokerage: {
              label: "个体司机订单收益返佣比例",
              value: "",
            },
          },
          raffle: {
            shared_count: {value:0},
            daily_online:{value:0},
          },
          cost:[]
        },
      },
      rules: {
        weight: [
          { required: true, message: "请输入会员权重", trigger: "blur" },
        ],
        title: [{ required: true, message: "请输入会员名称", trigger: "blur" }],
        note_price: [
          { required: true, message: "请输入短信单价", trigger: "blur" },
        ],
        give_note: [
          { required: true, message: "请输入短信赠送条数", trigger: "blur" },
        ],
        phone_price: [
          { required: true, message: "请输入网络电话单价", trigger: "blur" },
        ],
        give_phone: [
          {
            required: true,
            message: "请输入网络电话赠送条数",
            trigger: "blur",
          },
        ],
        voice_price: [
          { required: true, message: "请输入语音通知单价", trigger: "blur" },
        ],
        give_voice: [
          { required: true, message: "请输入语音赠送条数", trigger: "blur" },
        ],
        commission: [
          { required: true, message: "请输入提现手续费比例", trigger: "blur" },
        ],
        min_withdrawal: [
          { required: true, message: "请输入最低提现金额", trigger: "blur" },
        ],
        status: [
          { required: true, message: "请输入最低提现金额", trigger: "blur" },
        ],
      },
      data: [],
      time_options: [],
      time_options_copy: [],
    };
  },

  mounted() {
    console.log(this.id);

    this.get_config_list();
  },
  computed: {
    // 权限树选中数据
    checked() {
      let checked = [];
      this.$util.eachTreeData(this.authData, (d) => {
        if (d.checked && (!d.children || !d.children.length)) {
          checked.push(d.id);
        }
      });
      return checked;
    },
  },
  methods: {
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    // save() {
    //   console.log(this.tableData);
    // },
    //下拉选择时间
    handleSelectChange(value) {
      // console.log(value)
      let dur = this.form.cost[value].duration;
      const index = this.time_options_copy.findIndex(
        (option) => option.id === dur
      );
      this.form.cost[value].label = this.time_options_copy[index].name;
      let data = this.time_options_copy;
      const diff2 = data.filter(
        (item2) => !this.form.cost.some((item1) => item1.duration === item2.id)
      );
      // console.log(diff2);
      this.time_options = diff2;
    },
    get_config_list() {
      free_huiyuan_detail()
        .then((res) => {
          if (res.code === 200) {
            console.log(res.data);
            this.form = res.data;
            this.query();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((e) => {
          this.$message.error(e.message);
        });
    },
    // 获取权限树数据
    // query() {
    //   console.log("6666");
    //   this.authData = [];
    // },
    // handleCellEnter(row, column, cell, event) {
    //   row.isEdit = true;
    // },
    costAdd() {
      this.form.cost.push({
        duration: "",
        label: "",
        money: "",
        weight: 0,
      });
    },
    del(index) {
      console.log(index);
      this.form.cost.splice(index, 1);
      let data = this.time_options_copy;
      console.log(data);
      const diff2 = data.filter(
        (item2) => !this.form.cost.some((item1) => item1.duration === item2.id)
      );
      this.time_options = diff2;
    },
    /** 鼠标移出cell */

    get_detail() {

      if (this.id != 0) {
        huiyuan_detail(this.id).then((ress) => {
          if (ress.code === 200) {
            this.form = ress.data;
            setTimeout(() => {
              let data = this.time_options_copy;
              this.query();
              console.log(this.time_options_copy);
              console.log(data, this.form.cost);
              const diff2 = data.filter(
                (item2) =>
                  !this.form.cost.some((item1) => item1.duration === item2.id)
              );
              console.log(diff2);
              this.time_options = diff2;
            }, 2000);
          } else {
            this.$message.error(ress.msg);
          }
        });
      }
    },
    close_emit() {
      this.$emit("updatemite");
    },
    query() {
      console.log("6666");
      this.authData = [];
      if (!this.data) return;
      this.authLoading = true;
      // 获取到全部路由权限
      get_menu()
        .then((res) => {
          // console.log(res)
          if (res.code === 200) {
            this.authData = this.$util.toTreeData(res.data, "id", "pid");
            this.isCheck = true; //重点：给数节点赋值之前 先设置为true
            this.$nextTick(() => {
              //因为我是根据数据id来判断选中所以使用setCheckedKeys，具体可以查看element官网api
              this.$refs.tree.setCheckedKeys(this.form.usable_menus); //给树节点赋值
              this.isCheck = false; //重点： 赋值完成后 设置为false
            });
            if (this.form.usable_menus[0] == "") {
              this.isIndeterminate = false;
              this.checkAll = false;
            } else {
              //与全选关联起来，判断是否全部选中还是部分选中
              let menuOptions = [];
              menuOptions = this.convertTreeData(this.form.usable_menus);
              let checkedCount = this.form.usable_menus.length;
              this.checkAll = checkedCount === menuOptions.length;
              this.isIndeterminate =
                checkedCount > 0 && checkedCount < this.authData.length;
            }
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((e) => {
          this.$message.error(e.message);
        });

      //         // tree默认绑定的数据
      //         // this.$refs.tree.setCheckedKeys(res.data)
      //         // 如果第一条是空的选中状态则为false
      //         if (res.data[0] == "") {
      //           this.isIndeterminate = false;
      //           this.checkAll = false;
      //         } else {
      //           //与全选关联起来，判断是否全部选中还是部分选中
      //           let menuOptions = [];
      //           menuOptions = this.convertTreeData(res.data);
      //           console.log(res);
      //           let checkedCount = res.data.list.length;
      //           // console.log(res.data.list.length)
      //           console.log(checkedCount, menuOptions);
      //           // console.log(checkedCount===menuOptions.length)
      //           this.checkAll = checkedCount === menuOptions.length;
      //           this.isIndeterminate =
      //             checkedCount > 0 && checkedCount < this.authData.length;
      //         }
      //         // 关闭加载状态
      //         this.authLoading = false;
      //       } else {
      //         this.authLoading = false;
      //         this.$message.error(res.msg);
      //       }
      //     })
    },

    // tree 结构转化成一维数组
    convertTreeData(menuOptions) {
      for (let i = 0; i < menuOptions.length; i++) {
        if (menuOptions[i].children != undefined) {
          const temp = menuOptions[i].children;
          delete menuOptions[i].children;
          menuOptions = menuOptions.concat(temp);
        }
      }
      return menuOptions;
    },
    handleCheckAllChange() {
      if (this.checkAll) {
        //全选
        this.$refs.tree.setCheckedNodes(this.authData);
        let ids = this.$refs.tree
          .getCheckedKeys()
          .concat(this.$refs.tree.getHalfCheckedKeys());
        // console.log(ids)
        // this.$refs.tree.setCheckedKeys(ids.concat([1, 9, 10]));
      } else {
        //取消选中
        // this.$refs.tree.setCheckedKeys([1, 9, 10]);
      }
      this.isIndeterminate = false;
    },
    save(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let ids = this.$refs.tree
            .getCheckedKeys()
            .concat(this.$refs.tree.getHalfCheckedKeys());

            if(this.ids.length == 0){
                this.$message.error('请选择可用功能');
                return false
            }else {
                this.form.usable_menus = ids;
                this.form.id = 0;
                free_huiyuan_xiugai(this.form).then((res) => {
                    if (res.code === 200) {
                        this.$message.success(res.msg);
                        this.$emit("updatemite");
                    } else {
                        this.loading = false;
                        this.$message.error(res.msg);
                    }
                });
            }

        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.flex_choujiang {
  display: flex;
  border: 1px solid #e8eaec;
}
.border-right-left {
  border-left: 1px solid #e8eaec;
  border-right: 1px solid #e8eaec;
}
.border-top-border {
  border-top: 1px solid #e8eaec;
  // border-bottom:1px solid   #e8eaec;
}
.w-30 {
  width: 20%;
}
.flex_start {
  display: flex;
  justify-content: flex-start;
}
::v-deep .el-form-item__label {
  display: flex;
}
::v-deep .el-row {
  margin-bottom: 10px;
}
/deep/ .zZindex {
  z-index: 3000 !important;
}
</style>
